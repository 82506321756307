'use client';
import 'swiper/css';
import 'swiper/css/pagination';
import { FaChevronRight } from '@react-icons/all-files/fa/FaChevronRight';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import Link from 'next/link';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

type Props = {};

const Slider = (props: Props) => {
    const t = useTranslations('home');
    return (
        <>
            <Swiper
                loop={true}
                pagination={{
                    el: '#containerForBullets',
                    type: 'bullets',
                    bulletClass: 'w-3 h-3 bg-gray-300 rounded-full mx-1 inline-block cursor-pointer mx-2',
                    bulletActiveClass: '!bg-black',
                    clickable: true,
                    renderBullet: (index, className) => {
                        return `<span class="${className}"></span>`;
                    },
                }}
                autoplay={true}
                modules={[Pagination, Autoplay]}
                className='h-[478px] md:h-[680px] rounded-[40px]'
            >
                <SwiperSlide className='max-h-[680px] bg-white'>
                    <div
                        className='flex justify-start p-6 md:px-10 md:py-0 md:items-center gap-8 md:gap-0 flex-col md:flex-row h-full w-full'
                        style={{
                            background:
                                'linear-gradient(54deg,rgba(80, 173, 255, 0.5) -47.36%,rgba(218, 238, 255, 0.5) 72.67%,rgba(255, 255, 255, 0.5) 115.55%',
                        }}
                    >
                        <div className='w-full md:w-[422px] grow-0 shrink-0 flex flex-col gap-4'>
                            <p className='title-1-mobile md:title-1'>{t('freightCalAndOnlineBookings')}</p>
                            <p className='text-xs md:text-base text-gray-800'>
                                {t.rich('freightCalAndOnlineBookingsDes', {
                                    guidelines: (chunks) => <b>{chunks}</b>,
                                })}
                            </p>
                        </div>
                        <div className='relative'>
                            <picture className='w-[435px] md:w-[950px] flex absolute md:relative top-0 left-[150px] md:left-0'>
                                <Image
                                    src={'/assets/images/slider/slide1.png'}
                                    alt={'banner'}
                                    loading='lazy'
                                    width={950}
                                    height={575}
                                />
                            </picture>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide className='max-h-[680px] bg-white'>
                    <div className='flex justify-start p-6 md:px-10 md:py-0 md:items-center gap-8 md:gap-0 flex-col md:flex-row bg-[#000c37] h-full w-full'>
                        <div className='w-full md:w-[422px] grow-0 shrink-0 flex flex-col gap-4'>
                            <h3 className='title-1-mobile md:title-1 !text-white'>{t('extensiveTrackingSystem')}</h3>
                            <p className='text-xs md:text-base text-white'>
                                {t.rich('extensiveTrackingSystemDes', {
                                    guidelines: (chunks) => <b>{chunks}</b>,
                                })}
                            </p>
                        </div>
                        <div className='relative'>
                            <picture className='w-[435px] md:w-[950px] flex absolute md:relative top-0 left-[150px] md:left-0'>
                                <Image
                                    src={'/assets/images/slider/slide-tracking.png'}
                                    alt={'banner'}
                                    loading='lazy'
                                    width={858}
                                    height={783}
                                />
                            </picture>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide className='max-h-[680px] bg-white'>
                    <div
                        className='flex justify-start p-6 md:px-10 md:py-0 md:items-center gap-8 md:gap-0 flex-col md:flex-row bg-cover h-full w-full'
                        style={{
                            backgroundImage: 'url("/assets/images/slider/img-x2.png")',
                        }}
                    >
                        <div className='w-full md:w-[422px] grow-0 shrink-0 flex flex-col gap-4'>
                            <Image
                                src={'/assets/images/slider/logo.png?1'}
                                alt=''
                                loading='lazy'
                                width={131}
                                height={45}
                            />
                            <p className='title-1-mobile md:title-1'>{t('exclusiveNetwork')}</p>
                            <p className='text-xs md:text-base text-gray-800'>
                                {t.rich('exclusiveNetworkDes', {
                                    guidelines: (chunks) => <b>{chunks}</b>,
                                })}
                            </p>

                            <Link
                                href={'#'}
                                className='flex gap-2 items-center text-gray-800 cursor-pointer hover:text-primary'
                            >
                                <span className='font-semibold'>Show more</span>
                                <FaChevronRight size={14} />
                            </Link>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide className='max-h-[680px] bg-white'>
                    <div
                        className='flex justify-start p-6 md:px-10 md:py-0 md:items-center gap-8 md:gap-0 flex-col md:flex-row h-full w-full'
                        style={{
                            background: 'linear-gradient(60deg,#08f 0,#013dd8 100%)',
                        }}
                    >
                        <div className='w-full md:w-[422px] grow-0 shrink-0 flex flex-col gap-4'>
                            <div className='w-32 h-11 border py-2.5 px-4 rounded-2xl border-gray-300'>
                                <Image
                                    src={'/assets/images/slider/logo.png?1'}
                                    alt=''
                                    loading='lazy'
                                    width={131}
                                    height={45}
                                />
                            </div>
                            <h3 className='title-1-mobile md:title-1 !text-white'>{t('pOCoverage')}</h3>
                            <p className='text-xs md:text-base text-white'>
                                {t.rich('pOCoverageDes', {
                                    guidelines: (chunks) => <b>{chunks}</b>,
                                })}
                            </p>

                            <Link
                                href={'#'}
                                className='flex gap-2 items-center text-white cursor-pointer hover:text-primary'
                            >
                                <span className='font-semibold'>Show more</span>
                                <FaChevronRight size={14} />
                            </Link>
                        </div>
                        <div className='relative w-full'>
                            <picture className='w-[387px] md:w-auto flex justify-end absolute md:relative top-0 md:right-0'>
                                <Image
                                    src={'/assets/images/container-unloading.png'}
                                    alt={'banner'}
                                    loading='lazy'
                                    width={600}
                                    height={397}
                                />
                            </picture>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide className='max-h-[680px] bg-white'>
                    <div className='flex justify-start p-6 md:px-10 md:py-0 md:items-center gap-8 md:gap-0 flex-col md:flex-row bg-[#f5f6ff] h-full w-full'>
                        <div className='w-full md:w-[422px] grow-0 shrink-0 flex flex-col gap-4'>
                            <p className='title-1-mobile md:title-1'>{t('personalDashboard')}</p>
                            <p className='text-xs md:text-base text-gray-800'>
                                {t.rich('personalDashboardDes', {
                                    guidelines: (chunks) => <b>{chunks}</b>,
                                })}
                            </p>

                            <Link
                                href={'#'}
                                className='flex gap-2 items-center text-primary cursor-pointer hover:text-primary'
                            >
                                <span className='font-semibold'>Show more</span>
                                <FaChevronRight size={14} />
                            </Link>
                        </div>
                        <div className='relative'>
                            <picture className='w-[435px] md:w-[950px] flex absolute md:relative top-0 left-[150px] md:left-0'>
                                <Image
                                    src={'/assets/images/slider/slide-dashboard.png'}
                                    alt={'banner'}
                                    loading='lazy'
                                    width={858}
                                    height={783}
                                />
                            </picture>
                        </div>
                    </div>
                </SwiperSlide>
                {/* 
                <SwiperSlide className='max-h-[680px] bg-white'>
                    <div className='flex justify-start p-6 md:px-10 md:py-0 md:items-center gap-8 md:gap-0 flex-col md:flex-row bg-[#003944] h-full w-full'>
                        <div className='w-full md:w-[422px] grow-0 shrink-0 flex flex-col gap-4'>
                            <h3 className='title-1-mobile md:title-1 !text-white'>{t('logisticInsurance')}</h3>
                            <p className='text-xs md:text-base text-white'>
                                {t.rich('logisticInsuranceDes', {
                                    guidelines: (chunks) => <b>{chunks}</b>,
                                })}
                            </p>

                            <Link
                                href={'#'}
                                className='flex gap-2 items-center text-white cursor-pointer hover:text-primary'
                            >
                                <span className='font-semibold'>Show more</span>
                                <FaChevronRight size={14} />
                            </Link>
                        </div>
                        <div className='relative'>
                            <picture className='w-[435px] md:w-[950px] flex absolute md:relative top-0 left-[150px] md:left-0'>
                                <Image
                                    src={'https://www.searates.com/design/images/index/slider/slide10/img-x2.png?2'}
                                    alt={'banner'}
                                    width={858}
                                    height={783}
                                />
                            </picture>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide className='max-h-[680px] bg-white'>
                    <div
                        className='flex justify-start p-6 md:px-10 md:py-0 md:items-center gap-8 md:gap-0 flex-col md:flex-row h-full w-full'
                        style={{
                            background:
                                'linear-gradient(67deg,rgba(80,173,255,.5) -154.97%,rgba(218,238,255,.5) 39.21%,rgba(255,255,255,.5) 108.58%)',
                        }}
                    >
                        <div className='w-full md:w-[422px] grow-0 shrink-0 flex flex-col gap-4'>
                            <p className='title-1-mobile md:title-1'>{t('247Support')}</p>
                            <p className='text-xs md:text-base text-gray-800'>
                                {t.rich('247SupportDes', {
                                    guidelines: (chunks) => <b>{chunks}</b>,
                                })}
                            </p>

                            <Link
                                href={'#'}
                                className='flex gap-2 items-center text-primary cursor-pointer hover:text-primary'
                            >
                                <span className='font-semibold'>Chat now</span>
                                <FaChevronRight size={14} />
                            </Link>
                        </div>
                        <div className='relative w-full'>
                            <picture className='w-[265px] md:w-[502px] flex justify-end absolute md:relative top-0 right-0 md:right-0'>
                                <Image
                                    src={'https://www.searates.com/design/images/index/slider/slide8/img-x2.png?2'}
                                    alt={'banner'}
                                    width={460}
                                    height={502}
                                />
                            </picture>
                        </div>
                    </div>
                </SwiperSlide>

                <SwiperSlide className='max-h-[680px] bg-white'>
                    <div
                        className='flex justify-start p-6 md:px-10 md:py-0 md:items-center gap-8 md:gap-0 flex-col md:flex-row h-full w-full'
                        style={{
                            background: 'linear-gradient(90deg,#000518 -2.01%,#000828 30.82%)',
                        }}
                    >
                        <div className='w-full md:w-[422px] grow-0 shrink-0 flex flex-col gap-4'>
                            <h3 className='title-1-mobile md:title-1 !text-white'>{t('reduceBankCharges')}</h3>
                            <p className='text-xs md:text-base text-white'>
                                {t.rich('reduceBankChargesDes', {
                                    guidelines: (chunks) => <b>{chunks}</b>,
                                })}
                            </p>

                            <Link
                                href={'#'}
                                className='flex gap-2 items-center text-white cursor-pointer hover:text-primary'
                            >
                                <span className='font-semibold'>Show more</span>
                                <FaChevronRight size={14} />
                            </Link>
                        </div>
                        <div className='relative w-full justify-end'>
                            <picture className='w-[400px] md:w-auto flex justify-end'>
                                <Image
                                    src={'https://www.searates.com/design/images/index/slider/slide12/img-x2.png?2'}
                                    alt={'banner'}
                                    width={540}
                                    height={387}
                                />
                            </picture>
                        </div>
                    </div>
                </SwiperSlide> */}
            </Swiper>

            <div id='containerForBullets' className='flex justify-center mt-4'></div>
        </>
    );
};

export default Slider;
